import React from "react";
import "./Footer.css";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-content">
        {/* Navigation Section */}
        <nav className="footer-nav">
          <ul>
            <li>
              <a href="/about">About</a>
            </li>
            <li>
              <a href="/contact">Contact Us</a>
            </li>
            <li>
              <a href="/privacy-policy">Privacy Policy</a>
            </li>
            <li>
              <a href="/returns">Returns</a>
            </li>
            <li>
              <a href="/terms">Terms and Conditions</a>
            </li>
            <li>
              <a
                href="https://www.instagram.com/showbothrows"
                target="_blank"
                rel="noopener noreferrer"
              >
                Instagram
              </a>
            </li>
          </ul>
        </nav>
      </div>

      <div className="footer-copyright">
        <p>© 2024 Both Rows, LLC. All rights reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;
