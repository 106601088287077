/* global sTag */
import React from "react";

const SignupTrigger = () => {
  const handleSignupClick = () => {
    sTag("show");
  };

  return (
    <button onClick={handleSignupClick} className="signup-button">
      Sign Up for Text Alerts
    </button>
  );
};

export default SignupTrigger;
