import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Box,
  Button,
  Menu,
  MenuItem,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import InstagramIcon from "@mui/icons-material/Instagram";
import SpotifyIcon from "./SpotifyIcon";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import "./Header.css";

const Header = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md")); // Checks if the screen is mobile

  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [anchorElEvents, setAnchorElEvents] = useState(null);

  const handleMenuOpen = (event) => {
    setAnchorElEvents(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorElEvents(null);
  };

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!mobileMenuOpen);
  };

  return (
    <header className="header">
      {/* Slim Announcement Bar */}
      <Box className="announcement-bar-container">
        <AppBar position="static" className="announcement-bar">
          <Toolbar className="announcement-toolbar">
            <Typography variant="body2" className="announcement-text">
              Welcome to Both Rows! Embrace every moment, find your path to
              happy.
            </Typography>
          </Toolbar>
        </AppBar>
      </Box>

      {/* Social Media Icons */}
      <Box className="social-media-bar">
        <IconButton
          href="https://www.instagram.com/showbothrows"
          target="_blank"
          aria-label="Instagram"
          color="inherit"
        >
          <InstagramIcon />
        </IconButton>
        <IconButton
          href="https://open.spotify.com/playlist/0MJwAVcDsyKppzeg2yuxcZ?si=5e10f18257894699"
          target="_blank"
          aria-label="Spotify"
          color="inherit"
        >
          <SpotifyIcon />
        </IconButton>
      </Box>

      {/* Website Logo */}
      <Box className="logo-container">
        <Link to="/">
          <img
            src="/big_brlogo.png"
            alt="Both Rows Logo"
            className="website-logo"
          />
        </Link>
      </Box>

      {/* Navigation Bar */}
      <Box className="navigation-container">
        {isMobile ? (
          <>
            <IconButton
              edge="end"
              color="inherit"
              aria-label="menu"
              onClick={toggleMobileMenu}
            >
              <MenuIcon />
            </IconButton>
            {mobileMenuOpen && (
              <Box className="mobile-menu">
                <MenuItem
                  component="a"
                  href="https://shop.showbothrows.com"
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={toggleMobileMenu}
                >
                  Shop BR
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    window.location.href = "/events";
                    toggleMobileMenu();
                  }}
                >
                  Events
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    window.location.href = "/events/serotoninsoundwaves";
                    toggleMobileMenu();
                  }}
                >
                  Serotonin Soundwaves
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    window.location.href = "/about";
                    toggleMobileMenu();
                  }}
                >
                  About BR
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    window.location.href = "/contact";
                    toggleMobileMenu();
                  }}
                >
                  Contact Us
                </MenuItem>
                <MenuItem
                  component="a"
                  href="https://slktxt.io/10wJn"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Sign Up For Text Alerts
                </MenuItem>
              </Box>
            )}
          </>
        ) : (
          <Box className="desktop-menu">
            <Button
              component="a"
              href="https://shop.showbothrows.com"
              target="_blank"
              rel="noopener noreferrer"
              color="inherit"
            >
              Shop BR
            </Button>

            <Box display="flex" alignItems="center">
              <Button component="a" href="/events" color="inherit">
                BR Events
              </Button>
              <IconButton
                aria-controls="events-menu"
                aria-haspopup="true"
                onClick={handleMenuOpen}
                color="inherit"
              >
                <ExpandMoreIcon fontSize="small" />
              </IconButton>
            </Box>

            <Menu
              id="events-menu"
              anchorEl={anchorElEvents}
              open={Boolean(anchorElEvents)}
              onClose={handleMenuClose}
            >
              <MenuItem
                onClick={() => {
                  window.location.href = "/events/serotoninsoundwaves";
                  handleMenuClose();
                }}
              >
                Serotonin Soundwaves
              </MenuItem>
            </Menu>

            <Button
              color="inherit"
              onClick={() => (window.location.href = "/about")}
            >
              About BR
            </Button>
            <Button
              color="inherit"
              onClick={() => (window.location.href = "/contact")}
            >
              Contact Us
            </Button>
          </Box>
        )}
      </Box>
    </header>
  );
};

export default Header;
