import React from 'react';
import './PrivacyPolicyPage.css';

const PrivacyPolicyPage = () => (
  <main className="privacy-policy-page">
    <iframe
      src="/privacypolicy.pdf"
      width="100%"
      height="1000px"
      title="Privacy Policy"
    ></iframe>
  </main>
);

export default PrivacyPolicyPage;