import React from "react";
import "./HomePage.css";

const HomePage = () => {
  return (
    <div className="home-page">
      <div className="container">
        {/* Spotify Playlist Section */}
        <section className="spotify-section">
          <div className="spotify-embed">
            <iframe
              title="Spotify Playlist"
              style={{ borderRadius: "12px" }}
              src="https://open.spotify.com/embed/playlist/0MJwAVcDsyKppzeg2yuxcZ?utm_source=generator"
              width="100%"
              height="352"
              frameBorder="0"
              allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
              loading="lazy"
            ></iframe>
          </div>
        </section>

        {/* What is Both Rows Section */}
        <section className="about-section">
          <h2>What is Both Rows?</h2>
          <p>
            Both Rows was born out of a desire to inspire and guide people on
            their journey to happiness. The name itself symbolizes those moments
            of pure joy, where both rows of teeth are on full display in a
            genuine, wide smile. Our mission is to tackle the issue of waste by
            creating sustainable fashion from upcycled fabrics, all while
            promoting mental well-being. We believe in fashion that not only
            looks good but also makes you feel good—inside and out. Through our
            innovative pieces and community-driven events, we provide a space
            where you can freely explore, express yourself, and connect with
            others. Our goal is to craft dope, meaningful pieces that make
            people feel amazing and host events that bring communities together,
            delivering those serotonin-boosting moments we all crave.
          </p>
        </section>

        {/* Call to Action Buttons */}
        <section className="cta-section">
          <a
            href="https://shop.showbothrows.com"
            target="_blank"
            rel="noopener noreferrer"
            className="cta-button"
          >
            Checkout the BR Shop
          </a>
          <a href="/events" className="cta-button">
            See Latest BR Events
          </a>
        </section>
      </div>
    </div>
  );
};

export default HomePage;
