import React from "react";
import "./AboutPage.css";

const AboutPage = () => {
  return (
    <div className="about-page">
      <div className="container">
      {/* Page Header Section */}
      <section className="page-header">
        <h2>About The BR Brand</h2>
      </section>

      {/* Brand Description Section */}
      <section className="brand-description">
        <p>
          <strong>Both Rows</strong> was founded by Britney "Nerd" Epps in 2022
          as more than just a brand—it was born from an idea, a logo, and a
          powerful mission: to live as many moments as possible with both rows
          of teeth showing. Britney's smile journey began with fixing her smile
          to cover the bottom row, but in moments of complete joy and
          excitement, she can't help but reveal all her teeth.{" "}
          <strong>Both Rows</strong> symbolizes those moments of pure,
          uncontainable happiness. Yet, life isn't just about being happy; it's
          about experiencing all emotions, so when happiness comes, it hits even
          harder. The concept behind <strong>Both Rows</strong> is that
          happiness isn’t just about the feeling of joy but about understanding
          and embracing all emotions to find your way back to happiness.
        </p>
        <p>
          <strong>Both Rows</strong> as a collection and a community draws
          inspiration from everyday Black experiences. It aims to use upcycled
          fabric to create classic silhouettes and unique pieces, striving to be
          part of the solution to fabric waste rather than contributing to the
          problem. <strong>Both Rows</strong> and Nerd believe in exclusivity
          without being exclusionary, meaning the brand doesn’t produce large
          drops but instead focuses on delivering unique pieces accessible to
          everyone.
        </p>
        <p>
          Nerd learned to sew through a combination of YouTube tutorials,
          guidance from friends, and the expertise of her seamstress, who had
          been bringing her visions to life for years. As a software developer,
          Nerd applied her technical skills to fashion design, understanding the
          intricate details necessary for someone to truly love the clothes they
          wear. This journey represents the evolution of Nerd's fashion sense,
          blending both masculine and feminine elements to create something
          truly special.
        </p>
        <p>
          <strong>Both Rows</strong> is more than just a brand; it’s a
          journey—one that embraces growth, creativity, and the pursuit of
          happiness. We invite you to join us on this path, where every piece
          tells a story, and every story is a step closer to living life with
          both rows of teeth showing.
        </p>
      </section>

      {/* Thought Process Section */}
      <section className="thought-process-section">
        <div className="thought-process-content">
          <div className="thought-process-description">
            <h3>My Thought Process</h3>
            <p>
              For the longest time, and even now at times, I’ve struggled with
              how my brain works. It can feel overwhelming, and there are
              moments when I don't love how it operates. But in those same
              moments, it has the power to create something truly beautiful.
              Everything crafted within the Both Rows Universe is carefully
              thought out and curated to make a meaningful impact on the
              community.
            </p>
          </div>
          <div className="thought-process-video">
            <video
              src="/bc1.MOV"
              controls
              style={{ width: "50%", height: "auto", cursor: "pointer" }}
              onClick={() => {
                const videoElement = document.querySelector(
                  ".thought-process-video video"
                );
                if (videoElement.requestFullscreen) {
                  videoElement.requestFullscreen();
                }
              }}
            />
          </div>
        </div>
      </section>
      </div>
    </div>
  );
};

export default AboutPage;
