import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import HomePage from "./pages/HomePage";
import AboutPage from "./pages/AboutPage";
import PrivacyPolicyPage from "./pages/PrivacyPolicyPage";
import ContactPage from "./pages/ContactPage"; // Import the ContactPage component
import Header from "./components/Header";
import Footer from "./components/Footer";
import EventsPage from "./pages/EventsPage";
import SerotoninSoundwaves from "./pages/SerotoninSoundwaves";
import ReWearRevolution from "./pages/ReWearRevolution";
import TermsPage from "./pages/TermsPage";
import SignupTrigger from "./components/SignUpTrigger";
import "./App.css"; // Import main CSS file

// Create a custom theme
const theme = createTheme({
  palette: {
    primary: {
      main: "#000", // Black primary color
    },
    secondary: {
      main: "#fff", // White secondary color
    },
  },
  typography: {
    fontFamily: "Lato, sans-serif", // Default font for body text
    h1: {
      fontFamily: "Playfair Display, serif", // Header 1 font
    },
    h2: {
      fontFamily: "Merriweather, serif", // Header 2 font
    },
    h3: {
      fontFamily: "Playfair Display, serif", // Header 3 font
    },
    h4: {
      fontFamily: "Lato, sans-serif", // Header 4 font
    },
    h5: {
      fontFamily: "Lato, sans-serif", // Header 5 font
    },
    h6: {
      fontFamily: "Roboto, sans-serif", // Header 6 font
    },
    body1: {
      fontFamily: "Lato, sans-serif", // Primary body text font
    },
    body2: {
      fontFamily: "Roboto, sans-serif", // Secondary body text font
    },
  },
  components: {
    MuiToolbar: {
      styleOverrides: {
        root: {
          minHeight: "20px", // Override default min-height
          padding: 0, // Remove padding
          backgroundColor: "black", // Set background color to black
          color: "white", // Set text color to white
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          boxShadow: "none", // Remove default shadow
          backgroundColor: "black", // Force background color to black
        },
      },
    },
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <div>
          <Header />
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/about" element={<AboutPage />} />
            <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
            <Route path="/contact" element={<ContactPage />} />
            <Route path="/terms" element={<TermsPage />} />
            <Route path="/sign-up" elemt={<SignupTrigger />} />
            <Route path="/events" element={<EventsPage />} />
            <Route
              path="/events/serotoninsoundwaves"
              element={<SerotoninSoundwaves />}
            />
            <Route
              path="/events/rewearrevolution"
              element={<ReWearRevolution />}
            />
            {/* Add ContactPage route */}
          </Routes>
          <Footer />
        </div>
      </Router>
    </ThemeProvider>
  );
}

export default App;
