import React, { useState } from "react";
import "./ContactPage.css";
import emailjs from "emailjs-com";

const ContactPage = () => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    phone: "",
    email: "",
    subject: "",
    message: "",
  });

  const [error, setError] = useState("");
  const [showModal, setShowModal] = useState(false);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!formData.phone && !formData.email) {
      setError("Please fill out either Phone Number or Email Address.");
      return;
    }

    emailjs
      .sendForm(
        "service_h9e1qw6",
        "template_0gfz72e",
        e.target,
        "a3zjVjKG6bBc-4Py8"
      )
      .then(
        (result) => {
          console.log(result.text);
          setShowModal(true);
        },
        (error) => {
          console.log(error.text);
          setError(
            "There was an error submitting your inquiry. Please try again."
          );
        }
      );
  };

  const handleModalClose = () => {
    setShowModal(false);
    setFormData({
      firstName: "",
      lastName: "",
      phone: "",
      email: "",
      subject: "",
      message: "",
    });
    setError("");
  };

  return (
    <div className="contact-page">
      <div className="container">
        {/* Page Header Section */}
        <section className="page-header">
          <h2>Contact Us</h2>
        </section>

        {/* Contact Information Section */}
        <section className="contact-info">
          <p>
            You can reach out to <strong>Both Rows</strong> via Instagram DMs or
            by emailing us at{" "}
            <a href="mailto:info@showbothrows.com">info@showbothrows.com</a>. We
            aim to respond to all inquiries within 48 hours.
          </p>
        </section>

        {/* Inquiry Form Section */}
        <section className="inquiry-form">
          <form onSubmit={handleSubmit}>
            <div className="form-row">
              <div className="form-group">
                <label htmlFor="firstName">First Name</label>
                <input
                  type="text"
                  id="firstName"
                  name="firstName"
                  value={formData.firstName}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="lastName">Last Name</label>
                <input
                  type="text"
                  id="lastName"
                  name="lastName"
                  value={formData.lastName}
                  onChange={handleChange}
                  required
                />
              </div>
            </div>
            <div className="form-row">
              <div className="form-group">
                <label htmlFor="phone">Phone Number</label>
                <input
                  type="tel"
                  id="phone"
                  name="phone"
                  value={formData.phone}
                  onChange={handleChange}
                />
              </div>
              <div className="form-group">
                <label htmlFor="email">Email Address</label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="form-group">
              <label htmlFor="subject">Subject</label>
              <input
                type="text"
                id="subject"
                name="subject"
                value={formData.subject}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="message">Message</label>
              <textarea
                id="message"
                name="message"
                value={formData.message}
                onChange={handleChange}
                rows="5"
                required
              ></textarea>
            </div>
            {error && <p className="error-message">{error}</p>}
            <button type="submit" className="submit-button">
              Submit
            </button>
          </form>
        </section>

        {/* Success Modal */}
        {showModal && (
          <div className="modal">
            <div className="modal-content">
              <h3>Thank you!</h3>
              <p>
                Your inquiry has been submitted successfully. We will get back
                to you within 48 hours.
              </p>
              <button onClick={handleModalClose} className="modal-close-button">
                Close
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ContactPage;
