import React from "react";
import "./ReWearRevolution.css";

const ReWearRevolution = () => {
  return (
    <div className="rewear-revolution-page">
      <div className="container">
        <section className="page-header">
          <h2>ReWear Revolution</h2>
        </section>

        {/* New Image Section */}
        <section className="hero-image">
          <img src="/rewearrevolution.JPEG" alt="ReWear Revolution Hero" />
        </section>

        {/* Event Description Section */}
        <section className="event-description">
          <h3>What is ReWear Revolution?</h3>
          <p>
            <strong>ReWear Revolution</strong> is an event that celebrates
            sustainability, creativity, and mental well-being. It's a gathering
            where old clothes find new life, and participants connect over
            shared values of upcycling and self-care. The event encourages
            everyone to rethink fashion and embrace a more sustainable
            lifestyle, all while enjoying a vibrant, supportive community.
          </p>
        </section>

        {/* Upcoming Event Details Section */}
        <section className="upcoming-event">
          <h3>Upcoming Event</h3>
          <div className="event-content">
            <div className="event-image">
              <img src="/rr_flyer.JPEG" alt="ReWear Revolution Flyer" />
            </div>
            <div className="event-details">
              <h4>Event Details</h4>
              <p>
                <strong>Date:</strong> September 15, 2024
              </p>
              <p>
                <strong>Time:</strong> 01:00PM - 05:00PM
              </p>
              <p>
                <strong>Location:</strong> RSVP To Receive Address
              </p>
              <p>
                <strong>What to Expect:</strong> Join us for a day of upcycling
                and community bonding. Whether you're a seasoned upcycler or new
                to the concept, ReWear Revolution offers something for everyone
                who cares about sustainability and creativity.
              </p>
              <a
                href="https://www.eventbrite.com/e/rewear-revolution-a-clothing-swap-tickets-999821000527"
                target="_blank"
                rel="noopener noreferrer"
              >
                <button className="cta-button">RSVP Now</button>
              </a>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default ReWearRevolution;
