// src/components/SpotifyIcon.js

import React from "react";
import SvgIcon from "@mui/material/SvgIcon";

const SpotifyIcon = (props) => (
  <SvgIcon {...props}>
    <path d="M12 0C5.37279 0 0 5.37279 0 12C0 18.6272 5.37279 24 12 24C18.6272 24 24 18.6272 24 12C24 5.37279 18.6272 0 12 0ZM17.3086 17.0938C16.8633 17.708 16.0508 17.8809 15.4355 17.4355C12.7529 15.5703 9.24707 15.3838 6.31836 16.9082C5.63867 17.2754 4.80078 17.0137 4.43359 16.3359C4.06641 15.6562 4.32812 14.8184 5.00781 14.4512C8.58203 12.5586 12.8164 12.7852 15.998 14.9082C16.6133 15.3535 16.7852 16.1797 16.3398 16.7949L17.3086 17.0938ZM18.1484 13.0664C17.5703 13.7812 16.5742 13.9297 15.8594 13.3516C13.0312 10.998 8.918 10.6836 5.8125 12.4609C5.06641 12.8711 4.13281 12.6328 3.72266 11.8867C3.3125 11.1406 3.55078 10.207 4.29688 9.79688C8.08203 7.63672 13.3125 7.99609 16.8477 10.6406C17.5625 11.2188 17.7109 12.2148 17.1328 12.9297L18.1484 13.0664ZM18.75 9.11328C18.082 9.94922 16.8906 10.1133 16.0547 9.44531C12.8008 6.89844 7.19922 6.64062 3.71484 8.64844C2.80859 9.17969 1.60156 8.88672 1.07031 7.98047C0.539062 7.07422 0.832031 5.86719 1.73828 5.33594C5.86328 2.97656 12.1367 3.27734 16.5957 6.22656C17.4316 6.89453 17.5977 8.08594 16.9297 8.92188L18.75 9.11328Z" />
  </SvgIcon>
);

export default SpotifyIcon;
