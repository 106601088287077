import React from "react";
import "./SerotoninSoundwaves.css";

const SerotoninSoundwaves = () => {
  return (
    <div className="serotonin-soundwaves-page">
      <div className="container">
        {/* Hero Section */}
        <section className="page-header">
          <h2>Serotonin Soundwaves</h2>
        </section>

        {/* Event Description Section */}
        <section className="event-description">
          <h2>What is Serotonin Soundwaves?</h2>
          <p>
            <strong>Serotonin Soundwaves</strong> is an immersive day party
            experience that goes beyond just music. It's a celebration of mental
            health awareness, community, and the freedom to express your
            authentic self. Our event is designed to stimulate all your senses,
            with a focus on creating an atmosphere where serotonin levels soar,
            emotions flow freely, and connections are made. Through curated
            soundscapes, vibrant visuals, and a welcoming community, Serotonin
            Soundwaves aims to be a space where everyone can feel alive and in
            the moment.
          </p>
        </section>

        {/* Upcoming Event Details Section */}
        <section className="upcoming-event">
          <h2>Upcoming Event</h2>
          <div className="event-content">
            <div className="event-image">
              <img
                src="/serotoninsoundwaves.PNG"
                alt="Serotonin Soundwaves Flyer"
              />
            </div>
            <div className="event-details">
              <h3>Event Details</h3>
              <p>
                <strong>Date:</strong> September 28th, 2024
              </p>
              <p>
                <strong>Time:</strong> 4:00 PM - 7:00 PM
              </p>
              <p>
                <strong>Location:</strong> Location after ticket purchase. Will
                be a residence
              </p>
              <p>
                <strong>What to Expect:</strong> A day filled with music,
                positive vibes, and a community focused on mental well-being.
                Dance, connect, and let go in a space created for you to be
                yourself.
              </p>
              <button
                className="cta-button"
                onClick={() =>
                  window.open(
                    "https://shop.showbothrows.com/products/both-rows-presents-serotonin-soundwaves",
                    "_blank"
                  )
                }
              >
                Buy Tickets
              </button>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default SerotoninSoundwaves;
