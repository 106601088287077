import React from "react";
import "./TermsPage.css";

const TermsPage = () => {
  return (
    <div className="terms-page">
      <div className="container">
        {/* Page Header Section */}
        <section className="page-header">
          <h2>Terms and Conditions</h2>
        </section>

        {/* Terms and Conditions Content */}
        <section className="terms-content">
          <p>
            By signing up for our SMS alerts, you agree to receive text messages
            about Both Rows, including exclusive offers, event updates, and new
            collection drops. We respect your privacy and will never sell or
            share your information with third parties.
          </p>
          <p>
            You can expect no more than 5 messages per month. Standard message
            and data rates may apply. You can opt-out at any time by replying
            STOP to any of our messages.
          </p>
          <p>
            For more information, please contact us at info@showbothrows.com.
          </p>
        </section>
      </div>
    </div>
  );
};

export default TermsPage;
